import FrameComponent21 from "../components/FrameComponent21";
import FrameComponent11 from "../components/FrameComponent11";
import FrameComponent7 from "../components/FrameComponent7";
import GroupComponent from "../components/GroupComponent";
import "./Home.css";
import { Canvas } from "@react-three/fiber";
import { useGLTF,Stage,PresentationControls,OrbitControls,useAnimations,ScrollControls } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import Navbar from "../components/Navbar";




function Model(props){
  const{scene} = useGLTF("https://bzreports.s3.ap-south-1.amazonaws.com/Planeti/shape1.glb");
  useFrame(({ clock }) => {
    const rotationSpeed = Math.sin(clock.getElapsedTime()) * 0.01 + 0.005;
    scene.rotation.y += rotationSpeed;
  });
  return <primitive object={scene} {...props} />
  
}

const Home = () => {
  return (<>
  <Navbar activePage="home"/>
  <div className="home-page">
    
  <div className="section-1">
  <div className="home-page-inner">
    <div className="frame-parent7">
      <div className="introducing-rag-based-chatbot-parent">
        <h1 className="introducing-rag-based-chatbot-container">
          <p className="introducing-rag-based">Introducing RAG-Based</p>
          <p className="photogrammetry-3d">Chatbot Creator &</p>
          
          <p className="scanning-model">Photogrammetry</p>
          <p className="photogrammetry-3d">3D Scanning Model </p>
        </h1>
        <div className="welcome-to-the">
        Explore our revolutionary RAG-based chatbot and photogrammetry 3D scanning model, reshaping industries and technology interaction.
        </div>
      </div>
      <div className="explore-wrapper">
        <div className="explore">Explore</div>
      </div>
    </div>
  </div>
  <Canvas   dpr={[1,2]} shadows camera={{ fov: 45 }}  >
<color attach="background" args={["#ffffff"]} />

<ambientLight intensity={0.5} />
<directionalLight 
position={[10, 10, 5]} 
intensity={1} 
castShadow 
shadow-mapSize-width={1024} 
shadow-mapSize-height={1024} 
shadow-camera-far={50} 
shadow-camera-left={-10} 
shadow-camera-right={10} 
shadow-camera-top={10} 
shadow-camera-bottom={-10} 
/>
<PresentationControls speed={1.1}  maxDistance={1}>
<OrbitControls enableZoom={false}/>
<Stage environment={"sunset"}>
<ScrollControls damping={0.2} >
  <Model  scale={1.5} />
  </ScrollControls>
</Stage>
</PresentationControls>
</Canvas>
  </div>
  <FrameComponent21 />
  <FrameComponent11 />
  <FrameComponent7 />
  <GroupComponent />
</div>
  </>
    
  );
};

export default Home;
