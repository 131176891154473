import { useMemo } from "react";
import "./FrameComponent31.css";

const FrameComponent31 = ({
  adaptItToYourDataAndAdvan,
  genBotCanInstantlyHandleN,
  propPadding,
  propWidth,
  propRight,
  source
}) => {
  const frameSection1Style = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const frameDivStyle = useMemo(() => {
    return {
      width: propWidth,
      right: propRight,
    };
  }, [propWidth, propRight]);

  return (
    <section className="rag-page-inner1" style={frameSection1Style}>
      {/* <div className="rectangle-parent3">
        <img
          className="rectangle-icon"
          loading="lazy"
          alt=""
          src="/rectangle-491@2x.png"
        />
        <div
          className="adapt-it-to-your-data-and-adva-parent"
          style={frameDivStyle}
        >
          <h1 className="adapt-it-to">{adaptItToYourDataAndAdvan}</h1>
          <div className="genbot-can-instantly-handle-nu-wrapper">
            <div className="genbot-can-instantly">
              <p>{genBotCanInstantlyHandleN}</p>
              <img className="custom-chatbot" src={source} alt="custom"/>
            </div>
          </div>
          
        </div>
      </div> */}
      <div class="card3">
  <div class="card-content">
    <p class="card-heading">{adaptItToYourDataAndAdvan}</p>
    <p class="card-paragraph">{genBotCanInstantlyHandleN}</p>
    <img class="card-image" src={source} alt="Custom Chatbot" />
  </div>
</div>
    </section>
  );
};

export default FrameComponent31;
