import "./FrameComponent41.css";

const FrameComponent71 = () => {
  return (
    <section className="rag-page-child">

<div class="card-container">

  <div class="card">
    <img className="sales" src="/future.png" alt="Unlock Sales Protection"/>
    <h2 className="heading">FUTURE-PROOF TECHNOLOGY</h2>
    <p>Real Render, built on ever-advancing technology, ensures future-proof projects adaptable to evolving hardware and software, ideal for staying ahead in 3D modeling.</p>
  </div>


  <div class="card">
    <img className="time" src="/new.png" alt="Time Saving Efficiency"/>
    <h2 className="heading">UNLOCK NEW POSSIBILITIES</h2>
    <p>Real Render unlocks new possibilities for your projects by enabling you to create highly detailed 3D models quickly and easily</p>
  </div>

  <div class="card">
    <img className="mail" src="/creative.png" alt="Effortless Communication"/>
    <h2 className="heading">STREAMLINED CREATIVITY</h2>
    <p>Real Render accelerates creativity with seamless video-to-3D model conversion, optimizing workflow efficiency, and maximizing time for creation.</p>
  </div>
</div>
      {/* <div className="rectangle-parent2">
        <div className="frame-child3" />
        <div className="image">
          <div className="image1">
            <h1 className="unlock-sales-protection">UNLOCK SALES PROTECTION</h1>
            <div className="unlock-the-potential">
              Unlock the potential of your data with our advanced
              Retrieval-Augmented Generation (RAG) technology. Our powerful,
              accurate, and semantic search capabilities ensure that you find
              exactly what you're looking for with precision and efficiency. Say
              hello to a new era of search  technology that empowers you to
              discover information like never before
            </div>
          </div>
          <div className="us-corn-futures-fll-o-a-one-mo">
            <h1 className="time-saving-efficiency">TIME SAVING EFFICIENCY</h1>
            <div className="experience-the-time-saving">
              Experience the time-saving benefits of GenBot as it accelerates
              your workflow, saving you valuable minutesand enabling your
              company to focus on strategic tasks.
            </div>
          </div>
          <div className="effortless-communication-parent">
            <h1 className="effortless-communication">
              EFFORTLESS COMMUNICATION
            </h1>
            <div className="draft-emails-that">
              Draft emails that resonate with recipients and convey your message
              effectively using GenBot's intuitive Al.
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default FrameComponent71;
