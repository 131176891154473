import React from 'react'
import Navbar from "../components/Navbar";
import GroupComponent from '../components/GroupComponent';
import './Contact.css'

function Contact() {
  return (
    <div className='contact'>
     <Navbar activePage="contact"/>
     <div class="container2">
      <div class="form">
        <div class="contact-info">
          <h3 class="title">Let's get in touch</h3>
          <div class="info">
            <div class="information">
              <img src="img/location.png" class="icon" alt="" />{" "}
              <p> Mile End Rd, Bethnal Green, London E1 4NS</p>
            </div>
            <div class="information">
              <img class="icon" alt="" /> {/* how do i import icons? */}
              <p>hello@gmail.com</p>
            </div>
            <div class="information">
              <img src="img/phone.png" class="icon" alt="" />{" "}
              {/* how do i import icons? */}
              <p>123-456-789</p>
            </div>
          </div>

          <div class="social-media">
            <p>Connect with us :</p>
            <div class="social-icons">
              <a href="#">
                <i class="https://i.imgur.com/JkIA9rz.png"></i>
              </a>
              <a href="#">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="contact-form">
          <form>
            <h3 class="title">Contact us</h3>
            <div class="input-container">
              <input type="text" name="name" class="input" placeholder='Full Name' />
            </div>
            <div class="input-container">
              <input type="email" name="email" class="input" placeholder='Email' />

            </div>
            <div class="input-container">
              <input type="tel" name="phone" class="input" placeholder='Phone' />
   
            </div>
            <div class="input-container textarea">
              <textarea name="message" class="input" placeholder='Message'></textarea>
         
            </div>
            <input type="submit" value="Send" class="btn" />
          </form>
        </div>
      </div>
    </div>
    <GroupComponent />
    </div>
  )
}

export default Contact;

