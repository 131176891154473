import "./GroupComponent.css";

const GroupComponent = () => {
  return (
    <footer className="rectangle-group">
      <div className="frame-inner" />
      <div className="frame-parent39">
        <div className="footer-navigation-columns-wrapper">
          <div className="footer-navigation-columns">
            <div className="popular-features-parent">
              <div className="popular-features">Our Products</div>
              <div className="chat-parent">
                <a className="chat" href="/genbot"><div className="chat">Genbot</div></a>
                <a className="chat" href="/real-render"><div className="generate">Real Render</div></a>
                {/* <div className="summarize">Summarize</div>
                <div className="rerank1">Rerank</div>
                <div className="embedding">Embedding</div>
                <div className="semantic-search1">Semantic Search</div>
                <div className="classify">Classify</div> */}
              </div>
            </div>
            <div className="developers-parent">
              <div className="developers">Developers</div>
              <div className="developer-resources">
                {/* <div className="playground">Playground</div>
                <div className="llm-university1">LLM University</div> */}
                <div className="documentation">Documentation</div>
                <div className="api-reference">API Reference</div>
                {/* <div className="app-integrations">App Integrations</div>
                <div className="responsible-use">Responsible Use</div> */}
              </div>
            </div>
            <div className="navigation-column-pair">
              <div className="company">Company</div>
              <div className="company-navigation-pair">
              <a className="chat" href="/about"><div className="about">About</div></a>
                {/* <div className="blog">Blog</div>
                <div className="research">Research</div>
                <div className="careers">Careers</div>
                <div className="events">Events</div>
                <div className="newsrooms">Newsrooms</div> */}
                <div className="partners">Partners</div>
                <div className="prices">Prices</div>
              </div>
            </div>
            <div className="navigation-column-pair1">
              <div className="trust-center">Trust Center</div>
              <div className="privacy-container">
                <div className="privacy2">Privacy</div>
                <div className="terms-of-use">Terms of Use</div>
                {/* <div className="saas-agreement">SaaS Agreement</div>
                <div className="slo-agreement">SLO Agreement</div>
                <div className="responsibility">Responsibility</div> */}
                <div className="security">Security</div>
                {/* <div className="data-usage-policy">Data Usage Policy</div> */}
                {/* <div className="c4ai-cc-by-nc-license">
                  C4AI CC-BY-NC License
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-divider">
          <div className="footer-divider-inner">
            <div className="line-div" />
          </div>
          <div className="social-links-container">
            <img
              className="social-link-icon"
              loading="lazy"
              alt=""
              src="/frame.svg"
            />
            <img
              className="social-link-icon1"
              loading="lazy"
              alt=""
              src="/frame-1.svg"
            />
            <img
              className="social-link-icon2"
              loading="lazy"
              alt=""
              src="/frame-2.svg"
            />
          </div>
          <img
            className="legal-container-icon"
            loading="lazy"
            alt=""
            src="/frame-3.svg"
          />
          <div className="footer-divider-child">
            <div className="frame-child1" />
          </div>
        </div>
      </div>
      <div className="logo-container">
        <h1 className="logo"><span ><img className="logo" src="/logo2.svg" alt="logo"/></span></h1>
      </div>
    </footer>
  );
};

export default GroupComponent;
