import "./FrameComponent21.css";


import Carousel from "./Carousel";

const FrameComponent2 = () => {
  return (
    <section className="card-wrapper">
      <div className="card1">
        <div className="frame-parent32">
        <Carousel autoplay interval={7000}>
         
            <div className="frame-parent33">
              <div className="genbot-parent">
                <div className="genbot">GENBOT</div>
                <div className="rag-based-chatbot-parent">
                  <h1 className="rag-based-chatbot">RAG - based <br/>Chatbot Creator</h1>
                  <div className="our-rag-based">
                    Our RAG based chatbot harnesses the latest advancements in
                    Al technology to deliver a truly conversational experience.
                    With its ability to understand and respond to natural
                    language, our chatbot will redefine the way you interact
                    with technology.
                  </div>
                </div>
                <div className="learn-more-wrapper">
                  <div className="learn-more6">Learn More</div>
                </div>
              </div>
              <img
                className="rag-1-icon"
                loading="lazy"
                alt=""
                src="/rag-1@2x.png"
              />
            </div>
            <div className="frame-parent33">
           
                   <img
                className="rag-1-icon"
                loading="lazy"
                alt=""
                src="/realrender2-1@2x.png"
              />
              <div className="genbot-parent">
                <div className="genbot">REAL RENDER</div>
                <div className="rag-based-chatbot-parent">
                  <h1 className="rag-based-chatbot"> Photogrammetry 3D Scanning Model</h1>
                  <div className="our-rag-based">
                    Step into the world of photogrammetry with our advanced 3D
                    scanning models. Our innovative photogrammetry solutions
                    empower industries to create precise and detailed 3D
                    reconstructions, enabling in-depth visualization, analysis,
                    and optimization of assets.
                  </div>
                </div>
                <div className="learn-more-wrapper">
                  <div className="learn-more6">Learn More</div>
                </div>
              </div>
              {/* <img
                className="rag-1-icon"
                loading="lazy"
                alt=""
                src="/rag-1@2x.png"
              /> */}
            </div>
          
        
            {/* <div className="frame-parent34">
              <div className="real-render-parent">
                <div className="real-render">
                 
                 REAL RENDER
               
                </div>
                <div className="photogrammetry-3d-scanning-mod-parent">
                  <h1 className="photogrammetry-3d-scanning">
                    Photogrammetry 3D Scanning Model
                  </h1>
                  <div className="step-into-the">
                    Step into the world of photogrammetry with our advanced 3D
                    scanning models. Our innovative photogrammetry solutions
                    empower industries to create precise and detailed 3D
                    reconstructions, enabling in-depth visualization, analysis,
                    and optimization of assets.
                  </div>
                </div>
                <div className="learn-more-wrapper">
                  <div className="learn-more6">Learn More</div>
                </div>
              </div>
              <img
                className="real-render2-1-icon"
                loading="lazy"
                alt=""
                src="/realrender2-1@2x.png"
              />
          </div> */}
          </Carousel>
        </div>
        {/* <div className="decorative-element">
          <div className="decorative-element-child" />
          <div className="decorative-element-item" />
        </div> */}
      </div>
    </section>
  );
};

export default FrameComponent2;
