import FrameComponent61 from "../components/FrameComponent61";
import FrameComponent51 from "../components/FrameComponent51";
import FrameComponent41 from "../components/FrameComponent41";
import FrameComponent31 from "../components/FrameComponent31";
import FrameComponent7 from "../components/FrameComponent7";
import GroupComponent from "../components/GroupComponent";
import "./Product.css";
import Navbar from "../components/Navbar";

const Genbot = () => {
  return (<>
   <Navbar activePage="genbot" />
    <div className="rag-page">
         
      {/* <img className="d-model-11" alt="" src="/3d-model-1@2x.png" /> */}
      {/* <FrameComponent61 /> */}
      <div className="message-wrapper">
        <div className="message">
          <div className="container">
            <h1 className="enhance-your-conversational-container">
              <span>
                <p className="enhance-your">
                  Enhance your Conversational      
                </p>
                <p className="experiences-with-rag">Experiences with RAG</p>
              </span>
            </h1>
            <div className="experience-the-future">
              Experience the future of conversational AI with our RAG-based
              chatbot. Using advanced RAG (Retrieval Augmented Generation)
              technology, our chatbot provides personalized and accurate
              responses to your inquiries. Say goodbye to generic interactions
              and hello to a tailored conversational experience. Try it out now
              and see the difference.
            </div>
          </div>
          <div className="email-address">
            <div className="container1">
              <div className="learn-more">Learn More</div>
            </div>
          </div>
        </div>
        <img className="chatbot" src="/chatbot.png"/>
      </div>
      <FrameComponent51 heading="Advanced Natural Language Processing Capabilities" paragraph="Unleash the potential of our chatbot generator, fortified with
                  state-of-the-art NLP algorithms. Seamlessly handle
                  conversations with precision, from sentiment analysis to
                  entity recognition. Elevate user satisfaction by delivering
                  accurate and effective responses to their inquiries, driving
                  meaningful interactions powered by Al-driven language
                  understanding." source="./nlp.png" source2="./multiple.png" heading2="Seamless Integration  with Multiple Platforms" paragraph2="Our chatbot generator offers seamless integration with a Unleash
                  the potential of our chatbot generator, fortified with
                  state-of-the-art NLP algorithms. Seamlessly handle conversations
                  with precision, from sentiment analysis to entity recognition.
                  Elevate user satisfaction by delivering accurate and effective
                  responses to their inquiries, driving meaningful interactions
                  powered by Al-driven language understanding." heading3="What's possible with Genbot ?"/>
      <FrameComponent41 />
      <FrameComponent31
        adaptItToYourDataAndAdvan="Adapt it to your data and
Advanced use cases"
        genBotCanInstantlyHandleN="GenBot can instantly handle numerous tasks by default. But if you need to
incorporate company-specific language or more advanced use cases, then
GenBot can be customized to hyperfocus on your use case."
source="/custom.png"
      />
      <FrameComponent31
        adaptItToYourDataAndAdvan="Empower Your Enterprise with GenBot"
        genBotCanInstantlyHandleN="Revamp workflows with GenBot. Embrace unmatched innovation as GenBot
powers your enterprise with AI prowess. Enhance processes, elevate output,
and unveil growth horizons. Let GenBot fuel your journey towards a smarter
future."
        propWidth="862px"
        propRight="168.5px"
        source="/empower.png"
      />
      <FrameComponent7 />
      <GroupComponent />
    </div>
    </>
  );
};

export default Genbot;
