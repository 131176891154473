import "./FrameComponent81.css";

const FrameComponent81 = ({heading,heading2,heading3,paragraph,paragraph2,source,source2}) => {
  return (
    <section className="rag-page-inner">
      <div className="frame-parent47">
        <div className="frame-parent48">
          {/* <div className="rectangle-container">
            <div className="rectangle-div" />
            <div className="frame-wrapper13">
              <div className="advanced-natural-language-proc-parent">
                <h1 className="advanced-natural-language">
                  Advanced Natural Language Processing Capabilities
                </h1>
                <div className="unleash-the-potential">
                  Unleash the potential of our chatbot generator, fortified with
                  state-of-the-art NLP algorithms. Seamlessly handle
                  conversations with precision, from sentiment analysis to
                  entity recognition. Elevate user satisfaction by delivering
                  accurate and effective responses to their inquiries, driving
                  meaningful interactions powered by Al-driven language
                  understanding.
                </div>
              </div>
            </div>
            <img
              className="rag-1-icon1"
              loading="lazy"
              alt=""
              src="/nlp.png"
            />
          </div> */}
          <div className="rectangle-parent1">
           
          
            <div className="seamless-integration-with-mult-parent">
              <h1 className="seamless-integration-with-container1">
                <span>
                  <p className="seamless-integration">{heading}</p>
                
                </span>
              </h1>
              <div className="our-chatbot-generator1">
              {paragraph}
              </div>
            </div>
            <img className="real-render2-1-icon1"
              loading="lazy"
              alt=""
              src={source}/>
          </div>
          <div className="rectangle-parent1">
           
            <img
              className="real-render2-1-icon1"
              loading="lazy"
              alt=""
              src={source2}
            />
            <div className="seamless-integration-with-mult-parent">
              <h1 className="seamless-integration-with-container1">
                <span>
                  <p className="seamless-integration">{heading2}</p>
                  <p className="with-multiple-platforms">
                   
                  </p>
                </span>
              </h1>
              <div className="our-chatbot-generator1">
                {paragraph2}
              </div>
            </div>
          </div>
        </div>
        <div className="whats-possible-with-genbot-wrapper">
          <h1 className="whats-possible-with">{heading3}</h1>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent81;
