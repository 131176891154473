import { useMemo } from "react";
import "./FrameComponent7.css";

const FrameComponent = ({ propPadding }) => {
  const frameSectionStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  return (
    <section className="home-page-inner1" style={frameSectionStyle}>
      <div className="rectangle-parent">
        <div className="ready-to-get1">READY TO GET STARTED ?</div>
        <h1 className="build-with-planetai">Build with Planet.ai models ?</h1>
        <div className="read-th-docs-wrapper">
          <div className="read-th-docs">Read th Docs</div>
        </div>
        <img
          className="startup-icon"
          loading="lazy"
          alt=""
          src="/startup@2x.png"
        />
      </div>
    </section>
  );
};

export default FrameComponent;
