
import FrameComponent51 from "../components/FrameComponent51";

import FrameComponent7 from "../components/FrameComponent7";
import GroupComponent from "../components/GroupComponent";
import "./Product.css";
import Navbar from "../components/Navbar";
import FrameComponent81 from "../components/FrameComponent81";
import "./About.css"

const About= () => {
  return (<>
   <Navbar activePage="about" />
    <div className="rag-page">
         
      {/* <img className="d-model-11" alt="" src="/3d-model-1@2x.png" /> */}
      {/* <FrameComponent61 /> */}
      <div className="message-wrapper">
        <div className="message">
          <div className="container">
            <h1 className="enhance-your-conversational-container">
              <span>
                <p className="enhance-your">
                Transforming Industries with  
                </p>
                <p className="experiences-with-rag">Advanced AI Solutions </p>to Revolutionize Tomorrow
              </span>
            </h1>
            <div className="experience-the-future">
            Pioneering innovation, we're dedicated to crafting advanced AI solutions that redefine industries. Join us in shaping tomorrow's technology landscape and revolutionizing the way we live and work together.
            </div>
          </div>
          <div className="email-address">
            <a className="contact" href="/contact"><div className="container1">
              <div className="learn-more">Contact Us</div>
            </div></a>
          </div>
        </div>
        <img className="chatbot" src="/about.png"/>
      </div>
  <FrameComponent81 heading="Our Mission" paragraph="To empower businesses and individuals with transformative AI solutions, driving innovation and reshaping industries for a brighter future" source="/mission.png" source2="/vision1.png" heading2="Our Vision" paragraph2="To be the global leader in AI technology, inspiring progress and shaping a world where possibilities are limitless and innovation knows no bounds." />
      
      <GroupComponent />
    </div>
    </>
  );
};

export default About;
