import FrameComponent61 from "../components/FrameComponent61";
import FrameComponent51 from "../components/FrameComponent51";
import FrameComponent41 from "../components/FrameComponent41";
import FrameComponent31 from "../components/FrameComponent31";
import FrameComponent7 from "../components/FrameComponent7";
import GroupComponent from "../components/GroupComponent";
import "./Product.css";
import Navbar from "../components/Navbar";
import FrameComponent71 from "../components/FrameComponent71";

const RealRender = () => {
  return (<>
   <Navbar activePage="real-render" />
    <div className="rag-page">
         
      {/* <img className="d-model-11" alt="" src="/3d-model-1@2x.png" /> */}
      {/* <FrameComponent61 /> */}
      <div className="message-wrapper">
        <div className="message">
          <div className="container">
            <h1 className="enhance-your-conversational-container">
              <span>
                <p className="enhance-your">
                Introducing Advanced Photogrammetry
                </p>
                <p className="experiences-with-rag">3D Modeling</p>
              </span>
            </h1>
            <div className="experience-the-future">
              
Elevate your creative process with our state-of-the-art Photogrammetry 3D Model Generator. Seamlessly convert images into highly detailed 3D models with unparalleled accuracy and efficiency. Experience the future of 3D modeling today and unlock new possibilities for your projects.
            </div>
          </div>
          <div className="email-address">
            <div className="container1">
              <div className="learn-more">Learn More</div>
            </div>
          </div>
        </div>
        <img className="chatbot" src="/3d.png"/>
      </div>
      <FrameComponent51 heading="Turn your vision into reality" paragraph="Real render can seamlessly convert images into highly detailed 3D models with unparalleled accuracy and efficiency, helping you bring your vision to life. Experience the future of 3D modeling today and unlock new possibilities for your projects." source="./vision.png" heading2="Revolutionize your creative workflow" paragraph2="Are you ready to take your creative workflow to the next level? Our advanced Photogrammetry 3D Model Generator can transform the way you create and bring your ideas to life. With powerful technology and user-friendly interface, our tool delivers high-quality 3D models that make your projects stand out. Try it today and experience a new level of creativity!" source2="./workflow.png" heading3="What's possible with Real-render ?"/>
      <FrameComponent71 />
      <FrameComponent31
        adaptItToYourDataAndAdvan="High-Fidelity 3D Models"
        genBotCanInstantlyHandleN="Transform 360-degree videos of objects into detailed and accurate 3D models with our photogrammetry technology, capturing every intricate detail for realistic representations.

        "
source="/high.png"
      />
      <FrameComponent31
        adaptItToYourDataAndAdvan="Versatile Applications Across Various Fields"
        genBotCanInstantlyHandleN="Generated 3D models can be effortlessly integrated into a wide array of applications, spanning from product design and architectural visualization to cultural heritage preservation and medical simulation. "
        propWidth="862px"
        propRight="168.5px"
        source="/fields.png"
      />
      <FrameComponent7  />
      <GroupComponent />
    </div>
    </>
  );
};

export default RealRender;
