import "./FrameComponent11.css";

const FrameComponent1 = () => {
  return (
    <section className="home-page-child">
  <div class="card4">
  <h1 class="card-heading"> Igniting the Productivity Revolution</h1>
  <p class="card-paragraph"> Witness the dawn of a new era in productivity acceleration. Recent
              advancements in language models have already demonstrated a
              staggering 50% increase in efficiency across white-collar tasks.
              Prepare for the emergence of Intelligent Assistants poised to
              revolutionize decision-making within your enterprise, Equip your
              workforce with unprecedented tools to navigate data swiftly,
              paving the way for unparalleled productivity.</p>
              <p class="source"><strong>Source: Crafted with inspiration from cutting-edge industry insights.
</strong></p>
  <img class="card-image" src="/smallgirl.png" alt="Image"/></div>
      <div className="frame-parent35">
        {/* <div className="igniting-the-productivity-revo-wrapper">
          <h1 className="igniting-the-productivity-container">
            <span className="igniting-the-productivity-container1">
              <p className="igniting-the-productivity">
                Igniting the Productivity Revolution
              </p>
            </span>
          </h1>
        </div> */}
        <div className="frame-parent36">
          {/* <div className="productivity-icon-parent">
            <img
              className="productivity-icon"
              loading="lazy"
              alt=""
              src="/rectangle-49@2x.png"
            />
            <div className="witness-the-dawn">
              Witness the dawn of a new era in productivity acceleration. Recent
              advancements in language models have already demonstrated a
              staggering 50% increase in efficiency across white-collar tasks.
              Prepare for the emergence of Intelligent Assistants poised to
              revolutionize decision-making within your enterprise, Equip your
              workforce with unprecedented tools to navigate data swiftly,
              paving the way for unparalleled productivity ins.
            </div>
            <img className="subtract-icon" alt="" src="/subtract.svg" />
          </div> */}
          <div className="frame-wrapper12">
            <div className="the-enterprise-llm-parent">
              <h1 className="the-enterprise-llm">{`The Enterprise LLM `}</h1>
              <div className="feature-row-pair-parent">
                <div className="feature-row-pair">
                  <div className="feature-pair">
                    <div className="meine-chats">
                      <div className="letzte-chats">1</div>
                      <img
                        className="image-icon8"
                        loading="lazy"
                        alt=""
                        src="/image2@2x.png"
                      />
                    </div>
                    <div className="feature-title-pair">
                      <div className="customizable-models1">
                        CUSTOMIZABLE MODELS
                      </div>
                      <div className="planet-ais-offers-sophisticat">
                        Planet-ai's offers sophisticated customization (fine
                        tuning) tools and capabilities that give superior model
                        performance at industry-leading inference cost
                      </div>
                    </div>
                  </div>
                  <div className="feature-pair1">
                    <div className="feature-icon-pair">
                      <div className="icon-placeholder">2</div>
                      <img
                        className="feature-icon"
                        loading="lazy"
                        alt=""
                        src="/vector1.svg"
                      />
                    </div>
                    <div className="feature-title-pair1">
                      <div className="flexible-deployment-options1">
                        FLEXIBLE DEPLOYMENT OPTIONS
                      </div>
                      <div className="planetai-s-models">
                        Planet.ai' s models are accessible through a SaaS API,
                        cloud services (e.g., OCI, AWS SageMaker, Bedrock), and
                        private deployments (VPC and on-prem)
                      </div>
                    </div>
                  </div>
                </div>
                <div className="feature-row-pair1">
                  <div className="frame-parent37">
                    <div className="parent">
                      <div className="div1">3</div>
                      <img
                        className="c5a3bf5238d8e04dbcbfe2ecced11d-icon1"
                        loading="lazy"
                        alt=""
                        src="/63301010c5a3bf5238d8e04dbcbfe2ecced11db925x24svg1.svg"
                      />
                    </div>
                    <div className="performance-and-scalability-parent">
                      <div className="performance-and-scalability1">
                        PERFORMANCE AND SCALABILITY
                      </div>
                      <div className="planet-ais-models">
                        Planet. ai's models are packaged with inference model
                        performance at industry-leading inference cost engines
                        that deliver better runtime performance at a lower cost
                        than open-source equivalents
                      </div>
                    </div>
                  </div>
                  <div className="frame-parent38">
                    <div className="group">
                      <div className="div2">4</div>
                      <img
                        className="image-icon9"
                        loading="lazy"
                        alt=""
                        src="/image-12@2x.png"
                      />
                    </div>
                    <div className="privacy-group">
                      <div className="privacy1">PRIVACY</div>
                      <div className="customer-data-is1">
                        Customer data is never used in training base models, and
                        customers have complete control over customization and
                        model inputs/outputs.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 
    </section>
  );
};

export default FrameComponent1;
