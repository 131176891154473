import "./FrameComponent41.css";

const FrameComponent41 = () => {
  return (
    <section className="rag-page-child">

<div class="card-container">

  <div class="card">
    <img className="sales" src="sales.png" alt="Unlock Sales Protection"/>
    <h2 className="heading">UNLOCK SALES PROTECTION</h2>
    <p>Unleash data potential with our advanced Retrieval-Augmented Generation technology, revolutionizing search precision and efficiency.</p>
  </div>


  <div class="card">
    <img className="time" src="time.png" alt="Time Saving Efficiency"/>
    <h2 className="heading">TIME SAVING EFFICIENCY</h2>
    <p>Optimize workflow with GenBot, saving time and enabling strategic focus, enhancing company productivity.</p>
  </div>

  <div class="card">
    <img className="mail" src="mail.png" alt="Effortless Communication"/>
    <h2 className="heading">EFFORTLESS COMMUNICATION</h2>
    <p>Compose resonant, effective emails effortlessly using GenBot's intuitive AI, streamlining communication processes seamlessly.</p>
  </div>
</div>
      {/* <div className="rectangle-parent2">
        <div className="frame-child3" />
        <div className="image">
          <div className="image1">
            <h1 className="unlock-sales-protection">UNLOCK SALES PROTECTION</h1>
            <div className="unlock-the-potential">
              Unlock the potential of your data with our advanced
              Retrieval-Augmented Generation (RAG) technology. Our powerful,
              accurate, and semantic search capabilities ensure that you find
              exactly what you're looking for with precision and efficiency. Say
              hello to a new era of search  technology that empowers you to
              discover information like never before
            </div>
          </div>
          <div className="us-corn-futures-fll-o-a-one-mo">
            <h1 className="time-saving-efficiency">TIME SAVING EFFICIENCY</h1>
            <div className="experience-the-time-saving">
              Experience the time-saving benefits of GenBot as it accelerates
              your workflow, saving you valuable minutesand enabling your
              company to focus on strategic tasks.
            </div>
          </div>
          <div className="effortless-communication-parent">
            <h1 className="effortless-communication">
              EFFORTLESS COMMUNICATION
            </h1>
            <div className="draft-emails-that">
              Draft emails that resonate with recipients and convey your message
              effectively using GenBot's intuitive Al.
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default FrameComponent41;
