import React, {useState} from 'react'
import styles from './Navbar.module.css'
import { FaBars } from "react-icons/fa";
import { BiLogIn } from 'react-icons/bi';
import { FcTimeline } from "react-icons/fc";
import { AiOutlineClose } from "react-icons/ai";

function Navbar({ activePage }) {
  const [isShow, setIsShow] = useState(true)

  return (
    <nav>
   
      <p className={styles.logo}><img
              className="frame-child"
              loading="lazy"
              alt=""
              src="/logo1.svg"
            /></p>
        <div className={styles.menu}> {isShow ? <FaBars onClick={() => setIsShow(false)}/> : <AiOutlineClose onClick={() => setIsShow(true)}/>} 
        </div>
       <ul className={styles.navbar} id={isShow ? "" : styles.menu}>
        <li><a href='/' className={activePage === 'home' ? styles.active : ''}>HOME</a></li>
        <li><a href='/genbot' className={activePage === 'genbot' ? styles.active : ''}>GENBOT</a></li>
        <li><a href='/real-render' className={activePage === 'real-render' ? styles.active : ''}>REAL-RENDER</a></li>
        <li><a href='/about' className={activePage === 'about' ? styles.active : ''}>ABOUT</a></li>
        <li><a href='/contact' className={activePage === 'contact' ? styles.active : ''}>CONTACT</a></li>
      </ul>
      <div className={styles.profile}>
      {/* <BiLogIn/> */}
        <button className={styles.btn}>Try For Free</button>
    </div>
    </nav>
  )
}

export default Navbar
